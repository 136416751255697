import React from "react"
import { graphql } from "gatsby"
// import styled from "styled-components"

import SEO from "../components/seo"
import Layout, { CenterPage } from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HelpPost from "../components/HelpPost"

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout bgColor="LIGHTEST_GRAY">
      <SEO title={frontmatter.title} />
      <Header bgColor="TEAL" />
      <CenterPage>
          <HelpPost post={frontmatter}>
            {html}
          </HelpPost>
      </CenterPage>
      <Footer bgColor="BLACK" />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        post_type
        tags
        quote
        credit
        attribution
        tweet {
          id
          entities {
            urls {
              expanded_url
              title
            }
          }
        }
      }
    }
  }
`